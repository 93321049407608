import { CDN_PATH, PROD_ORIGIN } from '~/constants'

// Use CDN_PATH only in development mode
const baseCdnPath = process.env.NODE_ENV === 'development' && !process.env.IS_STORYBOOK ? CDN_PATH : ''
// Use PROD_ORIGIN only in production mode
const baseProdOrigin = process.env.NODE_ENV === 'development' ? PROD_ORIGIN : ''

export function requireSrcset(path: string, size: string, webp: boolean, v = 1): string {
  path = path.replace('{size}', size)
  let _path = path

  if (webp) {
    _path = _path.replace(/(jpg|png)/, 'webp')
  }

  return (
    baseCdnPath +
    _path.replace('{ratio}', '1') +
    `?v=${v}` +
    ' 1x,' +
    baseCdnPath +
    _path.replace('{ratio}', '2') +
    `?v=${v}` +
    ' 2x'
  )
}

export function requireImg(path: string, fallback?: string): string {
  path = path.replace('{size}', 'M').replace('{ratio}', '1')

  if (path.substring(0, 2) === './') {
    path = '/images/shop/' + path.substring(2)
  }

  return baseCdnPath + path
}

export function requireVideo(path: string): string {
  return baseCdnPath + path
}

//
export function getImageSourceForShoppingBag(Item: any) {
  if (Item.Thumbnail) {
    return Item.Thumbnail
  }
  if (Item.Diamonds?.[0]?.Picture) {
    return baseProdOrigin + Item.Diamonds[0].Picture
  }
  if (Item.diamonds?.[0]?.shapeImageName) {
    return baseProdOrigin + `/images/NewImages/Icons/Shapes/Large/${Item.diamonds[0].shapeImageName}.png`
  }
  return ''
}

export function isInViewport(element: any) {
  if (!element) return false

  const rect = element.getBoundingClientRect()
  const viewportHeight = window.innerHeight || document.documentElement.clientHeight

  return rect.bottom >= 0 && rect.top <= viewportHeight
}
